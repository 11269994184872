import axios from "axios";
import {apiConfig} from "../../utils/apiConfig";
import {addLogsAction} from "../reducers/appReducer";
import {
    setBinaryClassifierResAction, setProcessingResultAction,
    setProcessingTypeAction
} from "../reducers/batchProcessingReducer";
import {
    activateIntervalAction,
    changeProcessingProgressAction,
    changeUploadProgressAction,
    disableIntervalAction,
    hideUploaderAction,
    setUploadEvent,
    showUploaderAction
} from "../reducers/folderReducer";
import { Store } from 'react-notifications-component';
import authHeader from "./userAction";
import fileDownload from "js-file-download";

export const getBinaryClissificationAction = (petition,answer) => {
    return async (dispatch) => {
        try {
            const data = {
                "petition_text": petition,
                "answer_text": answer
            }
            const response = await axios.post('admin/poc/poc_petition_upload_single',
                data,
                {
                   // headers: await authHeader(),
                    ...apiConfig,
                })

            dispatch(setBinaryClassifierResAction(response.data.result))

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}

export const uploadPetitionAction = (body,mode="petition") => {
    return async (dispatch) => {
        try {
            dispatch(showUploaderAction())
            const url = mode === "petition" ? 'admin/poc/poc_petition_upload' : 'smart-report/poc_smart_upload/poc_smart_upload'
            const response = await axios.post(url,
                body,
                {
                   // headers: await authHeader(),
                    ...apiConfig,
                    onUploadProgress: ProgressEvent => {
                        const totalLength = ProgressEvent.lengthComputable ? ProgressEvent.total : ProgressEvent.target.getResponseHeader('content-length') || ProgressEvent.target.getResponseHeader('x-decompressed-content-length')

                        if (totalLength) {
                            let progress = Math.round((ProgressEvent.loaded * 100) / totalLength)
                            dispatch(changeUploadProgressAction(progress))
                        }
                    }
                })

            dispatch(setUploadEvent(response.data))
            dispatch(activateIntervalAction())

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const getPetitionEventsAction = (event_id,mode="petition") => {
    return async (dispatch) => {
        try {
            const url = mode === "petition" ? 'admin/poc/poc_petition_upload_status?session_id='+event_id : 'smart-report/poc_smart_upload/poc_smart_upload_status?session_id='+event_id

            const response = await axios.get(url, {
               // headers: await authHeader(),
                ...apiConfig,
            })

            const done = response.data.counter
            const total = response.data.count
            const finished = response.data.finished
            const ex = response.data.ex

            if (ex){
                dispatch(disableIntervalAction())
                dispatch(changeProcessingProgressAction(0))
                dispatch(hideUploaderAction())
                dispatch(changeUploadProgressAction(0))

                Store.addNotification({
                    title: "Ошибка",
                    message: ex,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }else {
                if (finished){

                    dispatch(disableIntervalAction())
                    dispatch(changeProcessingProgressAction(100))

                    let params = response.data.params

                    params.total = total
                    params.done = done
                    if (mode === "petition"){
                        if (params.useful === 0 && params.useless === 0 && params.formal === 0){
                            Store.addNotification({
                                title: "Ошибка",
                                message: "Не удалось классифицировать данные",
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                dismiss: {
                                    duration: 5000,
                                    onScreen: true
                                }
                            });
                        }else {

                            dispatch(setProcessingResultAction(params))
                            dispatch(setProcessingTypeAction("Бинарный классификатор текста"))
                            localStorage.setItem("stats_param",JSON.stringify(params))
                            localStorage.setItem("proc_type","Бинарный классификатор текста")
                            window.location.href = "/processing_stats";

                        }
                    }else {
                            if (params.clusters_create === 0 && params.clusters_new_create === 0) {
                                Store.addNotification({
                                    title: "Ошибка",
                                    message: "Не удалось добавить новые данные",
                                    type: "warning",
                                    insert: "top",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: true
                                    }
                                });
                            }else {

                                dispatch(setProcessingResultAction(params))
                                dispatch(setProcessingTypeAction("Диагностический интерфейс автоматизированной кластеризации и суммаризации"))
                                localStorage.setItem("stats_param",JSON.stringify(params))
                                localStorage.setItem("proc_type","Диагностический интерфейс автоматизированной кластеризации и суммаризации")
                                window.location = "/processing_stats";
                            }
                        }
                    setTimeout(() => {
                        dispatch(hideUploaderAction())
                        dispatch(changeProcessingProgressAction(0))
                    }, 4000);

                }else{
                    if (total !== 0){
                        dispatch(changeProcessingProgressAction(Math.round(done/total*100)))
                    }
                }
            }


        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}


export const downloadLastReportAction = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get('smart-report/poc_smart/get-last-report', {
                ...apiConfig,
            })

            function decodeBase64(base64) {
                const text = atob(base64);
                const length = text.length;
                const bytes = new Uint8Array(length);
                for (let i = 0; i < length; i++) {
                    bytes[i] = text.charCodeAt(i);
                }
                const decoder = new TextDecoder(); // default is utf-8
                return decoder.decode(bytes);
            }

            const blob = new Blob([decodeBase64(response.data)], { type: 'text/csv' })
            // Create an anchor element and dispatch a click event on it
            // to trigger a download
            const a = document.createElement('a')
            a.download = 'result.csv'
            a.href = window.URL.createObjectURL(blob)
            const clickEvt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            })
            a.dispatchEvent(clickEvt)
            a.remove()

        } catch (e) {
            dispatch(addLogsAction({status:"error",time:Date.now(),text:e.message}))
        }
    }
}
