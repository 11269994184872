import "./SmartReports.scss"
import ClustersComponent from "./ClustersComponent/ClustersComponent";
import ClustersMap from "./ClustersMap/ClustersMap";
import {useState} from "react";
import PetitionComponent from "./PetitionComponent/PetitionComponent";
import {downloadCSVDataAction, getClustersAction} from "../../store/actions/smartReportAdction";
import {useDispatch} from "react-redux";
import IconIrrow from "../../assets/icons/b09.svg"
import CalendarComponent from "./CalendarComponent/CalendarComponent";


function SmartReports() {

    const [showReport, setShowReport] = useState(false);
    const [groupBy,setGroupBy] = useState("регионы");
    const [checkedNew, setCheckedNew] = useState(false);
    const [title, setTitle] = useState("");

    const dispatch = useDispatch()
    if (!showReport) {
        localStorage.removeItem("map_settings")
        localStorage.removeItem("all_clusters")
    }

    const [period,setPeriod ] = useState([]);

    function handleBack(){
        setShowReport(false)

        let settings = JSON.parse(localStorage.getItem("settings_cluster"))
        settings.size = 5
        settings.page = 0

        localStorage.removeItem("map_settings")
        localStorage.removeItem("all_clusters")

        dispatch(getClustersAction(settings))
    }

    function handleCSVDownload(){
        let settings = JSON.parse(localStorage.getItem("settings_cluster"))
        delete settings.size
        delete settings.page
        delete settings.token

        let map_settings = JSON.parse(localStorage.getItem("map_settings"))
        if (map_settings) {
            settings.filter_map = map_settings.name
            settings.group_by = map_settings.type
        }
        dispatch(downloadCSVDataAction(settings))
    }

    return (
        <div className="smart_reports">

            <div className="header_block" style={{paddingTop:"24px",paddingLeft:"24px"}}>
                {
                    showReport ?
                        <button type="button" className="back_btn" onClick={handleBack}>
                            <img src={IconIrrow} alt="arrow"/>
                            <p className="gt_pt gt_gb">Назад</p>
                            <p className="gt_h4" style={{marginLeft:"20px"}}>{title}</p>
                        </button> :
                        <p className="gt_h4">
                            Кластеризация обращений
                        </p>
                }

                <div style={{display:"flex",gap:"24px"}}>
                    <button type="button" className="gt_btn_secondary" onClick={handleCSVDownload}>
                        <p className="gt_pt">
                            Выгрузить в CSV за период
                        </p>
                    </button>
                    <CalendarComponent setPeriod={setPeriod} />
                </div>




            </div>

            <div className="smart_reports_body">

                <ClustersComponent showReport={ showReport }
                                   setShowReport={ setShowReport }
                                   period={period}
                                   groupBy={groupBy}
                                   setGroupBy={setGroupBy}
                                   checkedNew={checkedNew}
                                   setCheckedNew={setCheckedNew}
                                   setTitle = {setTitle}
                                   title ={title}
                />
                {!showReport ? <ClustersMap period={period}
                                            setShowReport={setShowReport}
                                            setGroupBy={setGroupBy}
                                            groupBy={groupBy}
                                            checkedNew={checkedNew}
                                            setCheckedNew={setCheckedNew}
                                            setTitle={setTitle}
                /> : <PetitionComponent />}

            </div>

        </div>
    )
}

export default SmartReports;
